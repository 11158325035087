"use client";

import Link from "next/link";

import React, { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Container, Stack, Typography } from "@mui/material";

import { useCommonDataContext } from "@contexts";

import classes from "./Trusted.module.scss";

const MAX_TRUSTED = 6;

export const Trusted: FC = () => {
  const { trusted } = useCommonDataContext();

  if (!trusted?.length) {
    return null;
  }

  return (
    <Container>
      <Stack alignItems="flex-start" className={classes.trusted}>
        <Typography className={classes.trusted__title}>Trusted by:</Typography>
        <Stack
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          className={classes.trusted__inner}
        >
          {trusted.map(
            (item, key) =>
              key <= MAX_TRUSTED && (
                <Link key={item.uuid} href={item.website} target="_blank">
                  <Icon
                    src={item.image.length ? item.image : "/images/logo-short.svg"}
                    alt={item.display_name}
                    width={75}
                    height={53}
                    className={classes.trusted__img}
                    priority
                  />
                </Link>
              ),
          )}
        </Stack>
      </Stack>
    </Container>
  );
};
