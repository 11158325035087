"use client";

import { FC, useState } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Container, Stack, Typography } from "@mui/material";

import common from "@lib/styles/mainpage.module.scss";

import { Advantages } from "./Advantages";
import classes from "./WhyChoose.module.scss";

export const WhyChoose: FC = () => {
  const [activeItem, setActiveItem] = useState(1);

  return (
    <Box role="section" className={classes.wrapper}>
      <Container>
        <Typography variant="h2" className={common.title}>
          Why Choose Causevest
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.inner}
        >
          <Advantages onCheck={(key: number) => setActiveItem(key)} activeItem={activeItem} />
          <Icon
            src={`/images/advantages/a${activeItem}.svg`}
            alt={`advantage ${activeItem}`}
            width={511}
            height={403}
            className={classes.imgBlock}
          />
        </Stack>
      </Container>
    </Box>
  );
};
