"use client";

import * as React from "react";
import { FC, useState } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Container, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import classes from "./Variants.module.scss";

const initialState = {
  first: false,
  second: false,
  third: false,
};

export const Variants: FC = () => {
  const [selected, setSelected] = useState(initialState);

  return (
    <Box className={classes.variants}>
      <Container role="section" className={classes.variants__inner}>
        <Stack className={classes.variants__left}>
          <Typography variant="h2" className={classes.variants__title}>
            Causevest helps you to make a difference in the lives of those you care about
          </Typography>
          <Typography className={classes.variants__subtitle}>
            Get rewarded for supporting any type of good cause, large or small, with integrated
            analytics to trace donations, registration and social fundraising.
          </Typography>
        </Stack>
        <Box className={classes.variants__right}>
          <Icon
            src="/images/help.png"
            alt="help pic"
            width={431}
            height={408}
            className={clsx(classes.variants__rightBg, classes._desktop)}
          />
          <Icon
            src="/images/help.png"
            alt="help pic"
            width={276}
            height={261}
            className={clsx(classes.variants__rightBg, classes._mobile)}
          />
          <Box
            className={clsx(classes.variants__frame, {
              [classes._active]: selected === initialState,
            })}
          >
            <Icon
              src="/images/variants/variant-1.png"
              alt="variant-1"
              style={{ objectFit: "cover" }}
              className={clsx(classes.variants__pic, {
                [classes._firstPic]: selected.first,
              })}
              sizes="100%"
              fill
            />
            <Icon
              src="/images/variants/variant-2.png"
              alt="variant-2"
              style={{ objectFit: "cover" }}
              className={clsx(classes.variants__pic, {
                [classes._secondPic]: selected.second,
              })}
              sizes="100%"
              fill
            />
            <Icon
              src="/images/variants/variant-3.png"
              alt="variant-3"
              style={{ objectFit: "cover" }}
              className={clsx(classes.variants__pic, {
                [classes._thirdPic]: selected.third,
              })}
              sizes="100%"
              fill
            />
          </Box>
          <Icon
            src="/images/badgeBig.svg"
            alt="big badge"
            width={77}
            height={77}
            className={clsx(classes.variants__badge, classes._first)}
            onMouseEnter={() => setSelected({ ...initialState, first: true })}
            onMouseLeave={() => setSelected(initialState)}
          />
          <Icon
            src="/images/awardBig.svg"
            alt="big award"
            width={77}
            height={77}
            className={clsx(classes.variants__badge, classes._second)}
            onMouseEnter={() => setSelected({ ...initialState, second: true })}
            onMouseLeave={() => setSelected(initialState)}
          />
          <Icon
            src="/images/chartBig.svg"
            alt="big chart"
            width={77}
            height={77}
            className={clsx(classes.variants__badge, classes._third)}
            onMouseEnter={() => setSelected({ ...initialState, third: true })}
            onMouseLeave={() => setSelected(initialState)}
          />
        </Box>
      </Container>
    </Box>
  );
};
