import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Grid, Typography } from "@mui/material";
import clsx from "clsx";

import classes from "./WhyChoose.module.scss";

const ADVANTAGES: readonly string[] = Object.freeze([
  "Get sustainable contributions from your audience.",
  "Recieve support via donations towards your cause.",
  "Our technology lets you trace the end use of donated funds.",
  "We put our profits back into the good cause voted for by our users.",
  "Click to see how Causevest can help you.",
  "We charge on average 4% on contributions plus any card fees and that's it!",
]);

interface Props {
  onCheck: (key: number) => void;
  activeItem: number;
}

export const Advantages: FC<Props> = ({ onCheck, activeItem }) => (
  <Grid container spacing={2} className={classes.advantages}>
    {ADVANTAGES.map((item, key) => (
      <Grid
        item
        key={item}
        className={clsx(classes.advantages__item, {
          [classes._activeBlock]: activeItem === key + 1,
        })}
        onMouseEnter={() => onCheck(key + 1)}
      >
        <Typography className={classes.advantages__text}>{item}</Typography>
        <Icon
          src="/images/arrow-right.svg"
          alt="arrow right"
          width={18}
          height={18}
          className={clsx(classes.advantages__icon, {
            [classes._active]: activeItem === key + 1,
          })}
        />
      </Grid>
    ))}
  </Grid>
);
